var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CustomBottomSheet',{attrs:{"refName":'ReminderPlanInfo',"size":"xl","headerText":_vm.$t('ReminderPlans.data'),"headerIcon":_vm.reminderPlan.icon}},[_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.reminderPlan.fullCode,"title":_vm.$t('ReminderPlans.code'),"imgName":'code.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.reminderPlan.reminderPlanNameAr,"title":_vm.$t('ReminderPlans.nameAr'),"imgName":'reminderPlans.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.reminderPlan.reminderPlanNameEn,"title":_vm.$t('ReminderPlans.nameEn'),"imgName":'reminderPlans.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.reminderPlan.reminderPlanNameUnd,"title":_vm.$t('ReminderPlans.nameUnd'),"imgName":'reminderPlans.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.reminderPlan.reminderPlanDescriptionAr,"title":_vm.$t('ReminderPlans.descriptionAr'),"imgName":'description.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.reminderPlan.reminderPlanDescriptionEn,"title":_vm.$t('ReminderPlans.descriptionEn'),"imgName":'description.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.reminderPlan.reminderPlanDescriptionUnd,"title":_vm.$t('ReminderPlans.descriptionUnd'),"imgName":'description.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.reminderPlan.reminderPlanNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.isDataExist(
          _vm.reminderPlan.priorityTypeInfoBeforeTimeData.priorityTypeNameCurrent
        ),"title":_vm.$t('ReminderPlans.priorityTypeBeforeTime'),"imgName":'priorityTypes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.isDataExist(
          _vm.reminderPlan.priorityTypeInfoOnTimeData.priorityTypeNameCurrent
        ),"title":_vm.$t('ReminderPlans.priorityTypeOnTime'),"imgName":'priorityTypes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.isDataExist(
          _vm.reminderPlan.priorityTypeInfoAfterTimeData.priorityTypeNameCurrent
        ),"title":_vm.$t('ReminderPlans.priorityTypeAfterTime'),"imgName":'priorityTypes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.reminderPlan.reminderPlanActivationStatus
          ? _vm.$t('activeStatus')
          : _vm.$t('disActiveStatus'),"title":_vm.$t('activationStatus'),"imgName":'type.svg'}})],1),(_vm.reminderPlan.reminderPlansDetailsDataBeforeTime.length)?_c('div',{staticClass:"my-card row"},[_c('span',{staticClass:"my-card-title"},[_vm._v(_vm._s(_vm.$t("ReminderPlans.detailsBeforeTime")))]),_c('table',{staticClass:"my-table mt-3"},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v(_vm._s(_vm.$t("PriorityTypes.name")))]),_c('th',[_vm._v(_vm._s(_vm.$t("ReminderPlans.durationFrom")))]),_c('th',[_vm._v(_vm._s(_vm.$t("ReminderPlans.durationTo")))])])]),_c('tbody',_vm._l((_vm.reminderPlan.reminderPlansDetailsDataBeforeTime),function(type,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(++index))]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(type.priorityTypeInfoData.priorityTypeNameCurrent))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(type.reminderDurationFrom))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(type.reminderDurationTo))+" ")])])}),0)])]):_vm._e(),(_vm.reminderPlan.reminderPlansDetailsDataAfterTime.length)?_c('div',{staticClass:"my-card row"},[_c('span',{staticClass:"my-card-title"},[_vm._v(_vm._s(_vm.$t("ReminderPlans.detailsAfterTime")))]),_c('table',{staticClass:"my-table mt-3"},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v(_vm._s(_vm.$t("PriorityTypes.name")))]),_c('th',[_vm._v(_vm._s(_vm.$t("ReminderPlans.durationFrom")))]),_c('th',[_vm._v(_vm._s(_vm.$t("ReminderPlans.durationTo")))])])]),_c('tbody',_vm._l((_vm.reminderPlan.reminderPlansDetailsDataAfterTime),function(type,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(++index))]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(type.priorityTypeInfoData.priorityTypeNameCurrent))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(type.reminderDurationFrom))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(type.reminderDurationTo))+" ")])])}),0)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }